<template>
  <div class="exercise-list-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-400">
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Mã vận động"
                    placeholder="Nhập mã vận động"
                    name="codeParams"
                    :value.sync="filter.code"
                  ></basic-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Môn vận động"
                    placeholder="--- Chọn môn vận động  ---"
                    :options="category"
                    :value.sync="filter.exerciseCategoryId"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Hình thức vận động"
                    placeholder="--- Chọn hình thức vận động ---"
                    name="wards"
                    :apiPath="'/Admin/Exercise/ByCategory'"
                    :searchParams="{
                      categoryId: filter.exerciseCategoryId,
                    }"
                    :value.sync="filter.exerciseId"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-check-box
                    :options="[
                      {
                        text: 'Lọc các hình thức vận động mặc định',
                        value: true,
                      },
                    ]"
                    :value.sync="filter.default"
                  ></basic-check-box>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox-group
                    v-model="filter.status"
                    :options="[{ text: 'Lọc Inactive', value: true }]"
                  ></b-form-checkbox-group>
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/settings.svg" />
            </span>
            Setup 3 môn mặc định
          </template>
          <div style="margin: 1rem">
            <label>3 môn vận động mặc định</label>
            <basic-select
              class="mw-400"
              placeholder="--- Chọn môn vận động  ---"
              name="wards"
              :multiple="true"
              :taggable="true"
              :required="true"
              :options="category"
              :max="3"
              @selected="setupDefault"
              :value.sync="selectedDefaults"
              :solid="false"
              :allowEmpty="false"
            />
          </div>
        </b-dropdown>
        <template v-if="isWritePermission">
          <b-button
            class="btn btn-success mr-4"
            type="button"
            @click="createCategoryActivity"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
            </span>
            Thêm môn vận động mới
          </b-button>
          <b-button
            class="btn btn-success"
            type="button"
            @click="createActivity"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
            </span>
            Thêm hình thức vận động mới
          </b-button>
        </template>
      </template>
    </basic-subheader>
    <Wrapper>
      <b-overlay :show="loading">
        <v-data-table
          :headers="headers"
          :items="category"
          item-key="id"
          :single-expand="false"
          id="sortable"
          hide-default-footer
          :expanded.sync="expanded"
          ref="sortableTable"
          :items-per-page="-1"
          :options.sync="options"
        >
          <!-- show-expand -->
          <template v-slot:header.name="{ header }">
            <v-icon @click.stop="collapseAll(!isExpandedNew)"
              >mdi-{{
                isExpandedNew
                  ? 'unfold-more-horizontal'
                  : 'unfold-less-horizontal'
              }}
            </v-icon>
            {{ header.text }}
          </template>
          <template v-slot:item="{ item, expand, isExpanded }">
            <!-- :key="item.id" -->
            <tr
              :key="itemKey(item)"
              class="cursor-pointer sortableRow position-static"
            >
              <td class="mw-100 handle text-overflow">
                <div class="d-flex w-100 align-items-center">
                  <div class="svg-icon svg-icon-sm text-overflow">
                    <inline-svg
                      @click.stop="expand(!isExpanded)"
                      class="svg-icon mr-2 mw-30 sortHandle"
                      src="/media/svg/icons/Neolex/Basic/menu-dots.svg"
                    />

                    {{ item.code }}
                  </div>
                </div>
              </td>
              <td class="mw-250">
                <div class="d-flex justify-content-between align-items-center">
                  <div
                    class="svg-icon svg-icon-sm d-flex align-items-center success-color"
                  >
                    <inline-svg
                      @click.stop="expand(!isExpanded)"
                      class="svg-icon mr-2 mw-30"
                      :src="`/media/svg/icons/Neolex/Arrows/arrow-${
                        isExpanded ? 'bottom' : 'top'
                      }.svg`"
                    />
                    <span class="mr-2 mw-30"
                      ><img
                        :src="item.cover.url"
                        alt=""
                        onerror="javascript:this.src='/media/placeholder/placeholder-image.png'"
                        class="exercise-icon"
                    /></span>
                    <p class="d-flex align-items-center mb-0">
                      {{ item.name }}
                      <span v-show="item.default"> (mặc định)</span>
                    </p>
                  </div>

                  <span>
                    <action-dropdown
                      :value="item"
                      :show_copy="isWritePermission"
                      @copy="copyCategory"
                      @view="viewCategory"
                      :show_setDefault="false"
                      @edit="editCategory"
                      :show_delete="false"
                      :show_edit="isWritePermission"
                      :boundary="
                        category.length > 2 ? 'scrollParent' : 'window'
                      "
                    >
                      <template v-if="isWritePermission">
                        <b-dropdown-text
                          tag="div"
                          class="navi-item cursor-pointer"
                        >
                          <a
                            class="navi-link text-primary cursor-pointer"
                            @click.stop="createItemExercise(item)"
                          >
                            <span class="menu-icon svg-icon svg-icon-sm">
                              <inline-svg
                                class="svg-icon"
                                src="/media/svg/icons/Neolex/Basic/plus.svg"
                              />
                              Tạo hình thức vận động
                            </span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-text
                          tag="div"
                          class="navi-item cursor-pointer"
                        >
                          <a
                            class="navi-link cursor-pointer"
                            @click="deleteCategory(item)"
                          >
                            <span
                              class="menu-icon svg-icon svg-icon-sm"
                              :class="
                                item.status === 1
                                  ? 'text-danger'
                                  : 'text-primary'
                              "
                            >
                              <inline-svg
                                class="svg-icon"
                                src="/media/svg/icons/Neolex/Basic/power.svg"
                              />
                            </span>
                            <span
                              class="success navi-text"
                              :class="
                                item.status === 1
                                  ? 'text-danger'
                                  : 'text-primary'
                              "
                            >
                              {{ item.status === 1 ? 'Inactive' : 'Active' }}
                            </span>
                          </a>
                        </b-dropdown-text>
                      </template>
                    </action-dropdown>
                  </span>
                </div>
              </td>
              <td class="mw-100">
                <b style="color: #ff5756"> {{ item.mets }}</b>
              </td>
              <td class="mw-200">
                {{ item.intensityName }}
              </td>
              <td class="mw-200">
                {{ item.description }}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="p-0 position-static">
              <v-data-table
                :headers="headers"
                :calculate-widths="true"
                :items="item.exercises"
                hide-default-footer
                :items-per-page="-1"
                hide-default-header
                :options.sync="options"
                class="systems-parameter"
              >
                <template v-slot:item="{ item, expand, isExpanded }">
                  <tr
                    style="
                      border-bottom: 1px solid #ccc;
                      overflow: auto;
                      position: static;
                    "
                  >
                    <td class="mw-100 text-overflow">
                      <div class="text-overflow">
                        {{ item.code }}
                      </div>
                    </td>
                    <td class="mw-250">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div class="d-flex">
                          <span class="mr-2 mw-30"></span>
                          <span
                            class="menu-icon svg-icon svg-icon-sm d-flex align-items-center success-color mr-2 mw-30"
                            v-show="item.default"
                          >
                            <inline-svg
                              v-show="item.default === true"
                              class="svg-icon"
                              style="color: #4bb2ab"
                              src="/media/svg/icons/Neolex/Basic/check-mark.svg"
                            />
                          </span>
                          <span class="d-flex align-items-center">
                            {{ item.name }}</span
                          >
                        </div>
                        <span class="">
                          <action-dropdown
                            :value="item"
                            :show_copy="true"
                            @view="viewItemExercise"
                            @copy="copyItemExercise"
                            :show_setDefault="true"
                            @edit="editItemExercise"
                            @setDefault="setDefault"
                            :show_delete="false"
                            :boundary="
                              item.exercises && item.exercises.length > 2
                                ? 'scrollParent'
                                : 'window'
                            "
                          >
                            <b-dropdown-text
                              tag="div"
                              class="navi-item cursor-pointer"
                            >
                              <a
                                class="navi-link cursor-pointer"
                                @click.stop="deleteItemExercise(item)"
                              >
                                <span
                                  class="menu-icon svg-icon svg-icon-sm"
                                  :class="
                                    item.status == 1
                                      ? 'text-danger'
                                      : 'text-primary'
                                  "
                                >
                                  <inline-svg
                                    class="svg-icon"
                                    src="/media/svg/icons/Neolex/Basic/power.svg"
                                  />
                                </span>
                                <span
                                  class="success navi-text"
                                  :class="
                                    item.status === 1
                                      ? 'text-danger'
                                      : 'text-primary'
                                  "
                                >
                                  {{
                                    item.status === 1 ? 'Inactive' : 'Active'
                                  }}
                                </span>
                              </a>
                            </b-dropdown-text>
                          </action-dropdown>
                        </span>
                      </div>
                    </td>
                    <td class="mw-100">
                      <b style="color: #ff5756"> {{ item.mets }}</b>
                    </td>
                    <td class="mw-200">
                      {{ item.intensityName }}
                    </td>
                    <td class="text-left mw-200">
                      {{ item.description }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
        <!-- môn vận động -->
        <category-exercise
          :popupType="popupType"
          :category="category"
          :id.sync="id"
          :categorySelected.sync="categorySelected"
          @load="loadData"
        />
        <!-- môn vận động -->

        <!-- hình thức vận động -->
        <exercise-modal
          :popupType="popupCategoryType"
          :id.sync="idCategory"
          @load="loadData"
        />
        <!-- hình thức vận động -->
      </b-overlay>
    </Wrapper>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
export default {
  name: 'Exercise',
  components: {
    'category-exercise': () => import('./components/CategoryModal'),
    'exercise-modal': () => import('./components/Modal'),
  },
  data() {
    return {
      categorySelected: null,
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      popupCategoryType: 'create',
      popupType: 'create',
      isExpandedNew: true,
      isExpandedOld: true,
      options: {},
      filter: {
        default: null,
        code: null,
        status: null,
        exerciseCategoryId: null,
        exerciseId: null,
        sortBy: null,
        sortDesc: null,
      },
      selectedDefaults: [],
      defaults: [
        { id: 1, name: 'test' },
        { id: 2, name: 'test 1' },
        { id: 3, name: 'test 2' },
      ],
      expanded: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'Mã',
          value: 'code',
          width: '100px',
        },
        {
          text: 'Môn/Hình thức',
          value: 'name',
          sortable: true,
          width: '200px',
        },
        { text: 'METs', value: 'mets', width: '100px', sortable: true },
        {
          text: 'Loại cường độ',
          value: 'intensityName',
          width: '200px',
          sortable: true,
        },
        {
          text: 'Ghi chú',
          value: 'description',
          width: '200px',
          sortable: true,
        },
      ],
      data: [],
      category: [],
      id: null,
      idCategory: null,
      loading: false,
    };
  },
  created() {
    this.loadData();
  },

  watch: {
    options: {
      handler(newVal) {
        let { sortBy, sortDesc } = newVal;
        let [order] = sortDesc;
        let [sort] = sortBy;
        this.filter.sortBy = sort;

        this.filter.sortDesc = order ? 'desc' : 'asc';
      },
      deep: true,
    },
  },
  computed: {
    // FIX: fix update list after sort
    searchParams() {
      return {
        default: this.filter.default?.[0] ? true : false,
        code: this.filter.code,
        status: this.filter.status?.[0] ? 1 : null,
        exerciseCategoryId: this.filter.exerciseCategoryId,
        exerciseId: this.filter.exerciseId,
        orderBy: this.filter.sortBy
          ? `${this.filter.sortBy} ${this.filter.sortDesc}`
          : null,

        takeAll: true,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  methods: {
    async setDefault(item) {
      try {
        await this.$api.put('Admin/Exercise/Exercise/Default', {
          id: item.id,
        });
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Đặt làm mặc định thành công',
        });
        this.loadData();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },

    createItemExercise(item) {
      this.categorySelected = item;
      this.createActivity();
    },

    copyCategory(item) {
      this.idCategory = item.id;
      this.createCategoryActivity();
    },

    async changeOrder(oldIndex, newIndex, id) {
      try {
        await this.$api.put('Admin/Exercise/Order', {
          id,
          current: oldIndex + 1,
          new: newIndex + 1,
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.category.splice(oldIndex, 1)[0];
      this.changeOrder(oldIndex, newIndex, movedItem.id);
      this.category.splice(newIndex, 0, movedItem);
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey);
      return this.itemKeys.get(item);
    },
    viewCategory(item) {
      this.popupCategoryType = 'view';
      this.idCategory = item.id;
      this.$bvModal.show('exercise-modal');
    },
    editCategory(item) {
      this.popupCategoryType = 'detail';
      this.idCategory = item.id;
      this.$bvModal.show('exercise-modal');
    },
    async deleteCategory(item) {
      let payload = {};
      payload.status = item.status === 0 ? 1 : 0;
      payload.id = item.id;

      try {
        await this.$api.put('Admin/Exercise/Category/Status', payload);
        this.$toastr.s({
          title: 'Thành công !',
          msg: `${
            item.status === 0 ? 'Active thành công ' : 'Inactive thành công'
          }`,
        });
        this.loadData();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
    copyItemExercise(item) {
      this.id = item.id;
      this.createActivity();
    },
    viewItemExercise(item) {
      this.id = item.id;
      this.popupType = 'view';
      this.$bvModal.show('category-exercise-modal');
    },
    editItemExercise(item) {
      this.id = item.id;
      this.popupType = 'detail';
      this.$bvModal.show('category-exercise-modal');
    },
    async deleteItemExercise(item) {
      let payload = { ...item };
      payload.status = item.status === 0 ? 1 : 0;
      try {
        await this.$api.put('Admin/Exercise/Exercise/Status', payload);
        this.$toastr.s({
          title: 'Thành công !',
          msg: `${
            item.status === 0 ? 'Active thành công ' : 'Inactive thành công'
          }`,
        });
        this.loadData();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
    createCategoryActivity() {
      this.popupCategoryType = 'create';
      this.$bvModal.show('exercise-modal');
    },
    createActivity() {
      this.popupType = 'create';
      this.$bvModal.show('category-exercise-modal');
    },

    popupFilter() {},
    async setupDefault() {
      try {
        await this.$api.put('Admin/Exercise/Category/Default', {
          ids: this.selectedDefaults,
        });
        this.$toastr.s({
          title: 'Thành công! !',
          msg: 'Cập nhật môn mặc định thành công',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
    searchRequest() {
      this.loadData();
      document.body.click();
    },
    resetRequest() {
      this.filter.default = null;
      this.filter.code = null;
      this.filter.status = [];
      this.filter.exerciseCategoryId = null;
      this.filter.exerciseId = null;
      this.filter.sortBy = null;
      this.filter.sortDesc = null;
      this.$nextTick(() => {
        this.$validator.reset();
      });
      this.loadData();
      document.body.click();
    },
    handleUpdate() {
      this.$bvModal.show('system-modal');
    },

    async loadData() {
      this.loading = true;
      try {
        let { data } = await this.$api.get('Admin/Exercise/Category', {
          params: this.searchParams,
        });
        this.category = data;

        for (let el of data) {
          if (el.default === true) {
            this.selectedDefaults.push(el);
          }
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },

    collapseAll(isExpanded) {
      if (isExpanded) {
        this.$data.expanded = [];
      } else {
        this.$data.expanded = this.$data.category;
      }
      this.isExpandedNew = !this.isExpandedNew;
    },
  },

  mounted() {
    new Sortable(
      this.$refs.sortableTable.$el.getElementsByTagName('tbody')[0],
      {
        draggable: '.sortableRow',
        handle: '.sortHandle',
        onEnd: this.dragReorder,
      },
    );
  },
};
</script>

<style lang="scss" scoped>
.mw-400 {
  min-width: 400px;
}
.exercise-icon {
  display: block;
  min-width: 30px;
  font-size: 1.6rem;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: linear-gradient(
    113.04deg,
    #72cb9c -20.47%,
    rgba(230, 246, 237, 0) 59.72%
  );
}
.cursor-pointer {
  cursor: pointer;
}
.mw-250 {
  max-width: 250px !important;
  width: 250px !important;
}
.mw-100 {
  max-width: 100px !important;
  width: 100px !important;
}
.mw-200 {
  max-width: 200px !important;
  width: 200px !important;
}
.mw-30 {
  max-width: 30px !important;
  width: 30px !important;
}
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
